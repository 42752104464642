import { HomeContext } from './HomeContext'
import { useEffect, useMemo, useState } from 'react'
import { sweetError } from '@/utils/sweetalert2'
import { membershipsAsync } from '@/services/axios/guest/general'

export const HomeProvider = ({ children }) => {
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    const getCountrySelection = async () => {
      try {
        const { data: { records } } = await membershipsAsync()
        setSubscriptions(
          records
        )
      } catch (e) {
        sweetError('', e)
      }
    }
    getCountrySelection()
  }, [])

  const props = useMemo(() => ({
    subscriptions
  }), [subscriptions])

  return (
    <HomeContext.Provider value={props}>
      {children}
    </HomeContext.Provider>
  )
}
