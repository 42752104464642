import { post } from '../../api'

export async function countriesAsync () {
  return await post('general/select/countries')
}

export async function membershipsAsync () {
  return await post('general/select/memberships')
}

export async function termsAsync () {
  return await post('general/select/terms-and-condition')
}

export async function privacyAsync () {
  return await post('general/select/privacy-policy')
}
